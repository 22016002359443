@font-face {
    font-family: 'Neue Haas Grotesk';
    src: url('../assets/fonts/NEUEHAASGROTESKTEXT.TTF');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Garamond';
    src: url('../assets/fonts/AGaramondPro-Regular.otf');
    font-weight: normal;
    font-style: normal;

}

@import url('https://fonts.googleapis.com/css2?family=Modern+Antiqua&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');


#home-section
{
    display: flex;
    justify-content: center;
    overflow: hidden;
    cursor: default;
}

/* IDS */

#main
{
    width: 89.44vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    gap: 24px;
    grid-auto-rows: minmax(100px, auto);
}

nav
{
    padding-top: 36px;
    color: #110E0B;
}

nav h1
{
    font-size: 24px;
    font-weight: 45;
    font-family: "Neue Haas Grotesk";
    width: 244px;
    height: 27px;
    margin: 0;
    padding-bottom: 4px;
}

nav h2
{
    margin: 0;
    padding-bottom: 36px;
    font-family: "Neue Haas Grotesk";
    font-weight: 35;
    font-size: 12px;
}

nav div
{
    font-family: "Neue Haas Grotesk";
    font-weight: 45;
    font-size: 12px;
    padding-bottom: 16px;
    cursor: pointer;
}

#Info-grid
{
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    font-size: 13px;
}

#project-container
{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 20.83vw;
    width: 82.08vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
}



#project-container::-webkit-scrollbar
{
    display: none;
}


/* END IDS */

.one
{
    grid-column: 1;
    grid-row: 1/6;
}

.two
{
    grid-column: 1/13;
    grid-row: 1/7;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Neue Haas Grotesk";
    font-size: 10.805vw;
    font-weight: 65;
    line-height: 100%;
    z-index: -1;
}

.two div
{
    font-weight: 9000;
}

.three
{
    grid-column: 3/11;
    grid-row: 2/12;
    display: none;
    flex-direction: column;
    gap: 100px;
}

.three p
{
    font-family: "Neue Haas Grotesk";
    font-weight: bold;
    width: 800px;
    height: 75px;
    font-size: 12px;
    text-transform: uppercase;
}

.four
{
    grid-column: 2;
    grid-row: 3;
    width: 534px;
    font-weight: light;
    font-family: "Neue Haas Grotesk";
    font-size: 20px;
    display: none;
    color: black;
}

.four h1
{
    font-weight: 100;
    margin: 0px;
}

.project
{
    font-family: "Garamond";
    font-size: 60px;
    font-weight: 100;
    width: 1000px;
    transition: 1s ease;
}

.project a 
{
    color: black;
    text-decoration: none;
    transition: 0.3s ease;
    /* width: 1000px; */
}

.project:hover
{
    transition: 1s ease;
    transform: translateX(10%);
    color: gainsboro;
}

.project:hover a
{
    color: gainsboro;
}

#projects-section
{
    display: flex;
    flex-direction: column;
    font-family: "Neue Haas Grotesk";
    /* gap: -200px; */
}

#projects-section h4
{
    margin: 0px !important;
    font-size: 12px;
    font-weight: 100;
}

.red-square
{
    width: 8px;
    height: 8px;
    background-color: #B33032;
}

#project-square
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.link
{
    display: flex;
}

.link:hover
{
    transition: 0.5s ease !important;
    color: gainsboro !important;
}

.grid-one
{
    grid-column: 1;
    grid-row: 1;
    margin: 0px;
    line-height: 50%;
}

.grid-two
{
    grid-column: 3;
    line-height: 50%;

}

.grid-three
{
    grid-column: 1;
    grid-row: 2;
    line-height: 50%;

}

.grid-four
{
    grid-column: 3;
    grid-row: 2;
    line-height: 50%;

}

.five
{
    grid-column: 12;
    grid-row: 3;
    font-size: 15.97vmax;
    writing-mode: vertical-lr;
    transform: rotateZ(180deg);
    white-space: nowrap;
    align-items: start;
    justify-content: start;
    overflow: visible;
    font-family: "Saira Condensed";
    color: red;
    width: 13.88vw;
    height: 32.72vmax;
    display: none;
}

.five h1
{
    margin: 0px;
    letter-spacing: -1.38vw;
}

.six
{
    grid-column: 12;
    background-color: black;
    width: 29.29vmax;
    height: 100vh;
    display: none;
}

#github
{
    cursor: pointer;
}

#itchio
{
    cursor: pointer;
    
}

#linkedin
{
    cursor: pointer;
    
}

#shader
{
    cursor: pointer;
    
}

.slideIn1s
{
    animation: slideRight 1s ease forwards;
}

.slideIn2s
{
    animation: slideRight 1.25s ease forwards;
}

.slideIn3s
{
    animation: slideRight 1.5s ease forwards;
}

.slideIn4s
{
    animation: slideRight 1.75s ease forwards;
}

.slideIn5s
{
    animation: slideRight 2s ease forwards;
}

.slideIn6s
{
    animation: slideRight 2.25s ease forwards;
}

.slideIn7s
{
    animation: slideRight 2.5s ease forwards;
}

.slideIn8s
{
    animation: slideRight 2.75s ease forwards;
}

.slideIn9s
{
    animation: slideRight 3s ease forwards;
}

.slideOut1s
{
    animation: slideLeft 1s ease forwards;
}

.slideOut2s
{
    animation: slideLeft 1.25s ease forwards;
}

.slideOut3s
{
    animation: slideLeft 1.5s ease forwards;
}

.slideOut4s
{
    animation: slideLeft 1.75s ease forwards;
}

.slideOut5s
{
    animation: slideLeft 2s ease forwards;
}

.slideOut6s
{
    animation: slideLeft 2.25s ease forwards;
}

.slideOut7s
{
    animation: slideLeft 2.5s ease forwards;
}

.slideOut8s
{
    animation: slideLeft 2.75s ease forwards;
}

.slideOut9s
{
    animation: slideLeft 3s ease forwards;
}

.slideUp
{
    animation: slideUp 0.5s ease forwards;
}

.slideToRight
{
    animation: slideToRight 0.5s ease forwards;
}

.slideToLeft
{
    animation: slideToLeft 0.5s ease forwards;
}

.slideDown
{
    animation: slideDown 1.5s ease forwards;
}

.slideUp200
{
    animation: slideUp200 1.5s ease forwards;
}

@media screen and (min-width: 1500px) 
{
    .five
    {
    font-size: 14.97vmax;
    width: 12.88vw;
    height: 31.72vmax;

    }
}

@media screen and (max-width: 1250px) 
{
    .two
    {
        align-items: end;
        justify-content: end;
        text-align: right;
    }
}

@media screen and (min-width: 2000px) 
{

.project-item
{
    font-size: 80px;
}

.project
{
    width: 2000px;
}

#project-container
{
    height:25.83vw;
}

#about-me
{
    /* margin-top: 100px; */
    /* font-size: 18px; */
}

}

@keyframes slideLeft {
    from
    {
        transform: translateX(100vw);
    }
    to
    {
        transform: translateX(0%);
    }
}

@keyframes slideRight {
    from
    {
        transform: translateX(0%);
    }
    to
    {
        transform: translateX(100vw);
    }
}

@keyframes slideUp {
    from
    {
        transform: translateY(100vw);
    }
    to
    {
        transform: translateY(0%);
    }
}

@keyframes slideUp200 {
    from
    {
        transform: translateY(0%);
    }
    to
    {
        transform: translateY(-100vw);
    }
}

@keyframes slideToRight {
    from
    {
        transform: translateX(-100vw);
    }
    to
    {
        transform: translateX(0%);
    }
}

@keyframes slideToLeft {
    from
    {
        transform: translateX(0%);
    }
    to
    {
        transform: translateX(-100vw);
    }
}

@keyframes slideDown {
    from
    {
        transform: translateY(0%);
    }
    to
    {
        transform: translateY(100vw);
    }
}